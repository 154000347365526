.acknowledgements{
    padding: 48px 0;
}

.acknowledgements h1{
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.5px;
    text-align: center;
    margin-bottom: 48px;
}

.logos{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
}

.logos img{
    height: 56px;
}

.logos img:not(:last-of-type){
    margin-right: 32px;
}

.text{
    max-width: 340px;
    margin: auto;
    opacity: .7;
}

@media (max-width: 768px){
    .logos {
        flex-direction: column;
        margin-bottom: none;
    }
    .logos img {
        margin: 12px 0px;
    }
    .logos img:not(:last-of-type){
        margin-right: none;
    }
    .acknowledgements h1{
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.4px;
    }
}