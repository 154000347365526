.loader{
    min-height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
}

.element{
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px transparent solid;
    border-top-color: var(--primary);
    animation: load 2s ease infinite;
}

@keyframes load{
    from{
        transform: none;
    }
    to{
        transform: rotate(359deg);
    }
}