.us{
    padding: 48px 0;
}

.us h1{
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.5px;
    text-align: center;
    margin-bottom: 56px;
    transition: transform .3s ease, opacity .3s ease;
    transform: translateY(24px);
    opacity: 0;
    transition-delay: 0.8s;
}

.show.us h1{
    transform: none;
    opacity: 1;
}

.flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo{
    margin: 16px;
    width: 96px;
    height: 96px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    /*background-color: var(--secondary);*/
    background: linear-gradient(10deg, var(--primary), var(--secondary));
    box-shadow: 0 6px 16px -2px rgba(0,0,0,.12);
    transform: scale(0);
    transition: transform .2s ease;
    transition-delay: 0.8s;
}

.show .logo{
    transform: none;
}

.logo img{
    display: block;
    filter: invert(1) brightness(200%);
}

.column{
    width: calc(50% - 72px);
}

.column:first-child{
    text-align: right;
}

.column:first-child .item{
    margin-left: auto;
}

.item{
    width: fit-content;
    padding: 16px;
    margin-bottom: 24px;
    /*box-shadow: 0 4px 16px -2px rgba(0,0,0,.12);*/
    /*background: linear-gradient(35deg, var(--primary), var(--secondary));*/
    background-color: var(--primary);
    color: var(--back);
    border-radius: 17px;
    transform: scale(0);
    opacity: 0;
    transition: transform .5s ease, opacity .5s ease;
}

.show .item{
    opacity: 1;
    transform: none;
}

.item h3{
    margin-bottom: 4px;
}

.item p{
    margin: 0;
    font-size: 14px;
}

.column:first-child .item:first-child{
    border-bottom-right-radius: 0px;
    transform-origin: bottom right;
    transition-delay: 1.2s;
}

.column:first-child .item:last-child{
    border-top-right-radius: 0px;
    transform-origin: top right;
    transition-delay: 1.4s;
}

.column:last-child .item:first-child{
    border-bottom-left-radius: 0px;
    transform-origin: bottom left;
    transition-delay: 1.6s;
}

.column:last-child .item:last-child{
    border-top-left-radius: 0px;
    transform-origin: top left;
    transition-delay: 1.8s;
}

@media (min-width: 769px){
    .column:first-child .item:first-child{
        margin-right: -12px;
    }
    .column:last-child .item:last-child{
        margin-left: -12px;
    }
    .logo{
        margin-top: -12px;
    }
}

@media (max-width: 768px){
    .us h1{
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.4px;
    }
    .logo{
        display: none;
    }
    .flex{
        display: block;
    }
    .column{
        width: 100%;
    }
    .column .item{
        border-radius: 12px!important;
        width: 100%;
        text-align: left!important;
    }
}