.webinars{
    padding: 48px 0;
}

.webinars h1{
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.5px;
    text-align: center;
    margin-bottom: 56px;
}

.items{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;
    justify-items: center;
}

.items a{
    text-decoration: none!important;
    transition: transform .2s ease;
}

.items a:hover{
    transform: scale(1);
}

@media (max-width: 768px){
    .webinars h1{
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.4px;
    }
    .items{
        grid-template-columns: 1fr 1fr;
    }
}


@media (max-width: 498px){
    .items{
        grid-template-columns: 1fr;
    }
}