.grid{
    min-height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 48px;
    padding-top: 84px;
}

.image, .image img{
    width: 100%;
}

.image img{
    transform: scale(.5);
    opacity: 0;
    transition: opacity .4s ease, transform .4s ease;
    transition-delay: .8s;
}

.text h1{
    font-size: 56px;
    letter-spacing: -1px;
    line-height: 60px;
    opacity: 0;
    transition: opacity .3s ease, transform .3s ease;
    transform: translateY(-24px);
    transition-delay: .4s;
}

.text p{
    font-size: 18px;
    line-height: 28px;
    opacity: 0;
    transform: translateY(-24px);
    transition: opacity .2s ease, transform .2s ease;
    transition-delay: .7s;
}

.show .text h1{
    opacity: 1;
    transform: none;
}

.show .text p{
    opacity: 1;
    transform: none;
}

.show .image img{
    transform: none;
    opacity: 1;
}

@media (max-width: 1024px){
    .text h1{
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -0.5px;
    }
    .text p{
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.1px;
    }
}

@media (max-width: 768px){
    .hero{
        padding: 24px 0;
    }
    .grid{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        align-items: unset;
    }
    .grid .image{
        grid-row: 1;
        text-align: center;
        align-self: end;
    }
    .grid .image img{
        max-width: 400px;
    }
    .grid .text{
        grid-row: 2;
        text-align: center;
    }
    .text h1{
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.4px;
    }
    .text p{
        max-width: 480px;
        margin: auto;
    }
}