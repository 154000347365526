.logoBold {
    font-weight: bold;
}

.logo{
    justify-self: center;
    margin: auto;
    margin-right: 24px;
    font-family: Manrope;
}
.nav{
    padding: 24px 0;
    position: fixed;
    width: 100%;
    background-color: var(--back);
    border-bottom: 1px var(--border) solid;
    z-index: 99;
}

.navcontainer {
    max-width: 1210px;
    padding: 0 24px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav :global(.container){
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logos{
    display: flex;
    align-items: center;
}

.logos *{
    display: block;
    margin-right: 16px;
}

.logos img{
    height: 28px;
}

.links{
    display: flex;
    align-items: center;
    font-size: 14px;
}

.links a{
    display: block;
    padding: 4px 8px;
    border-radius: 5px;
}

.links a:hover{
    text-decoration: none;
    background-color: rgba(0,0,0,.05);
}

.links a:not(:last-of-type){
    margin-right: 8px;
}

.burgerWrap{
    display: none;
}

.langWrapper {
    display: flex;
    align-items: center;
}

.lang {
    position: relative;
    padding: 4px;
    border-radius: 5px;
    margin-right: 8px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    animation: fadeIn 1.6s 1s cubic-bezier(0.19, 1, 0.22, 1) both;
}

.lang svg {
    margin: 0 2px;
}

.lang:hover {
    background-color: rgba(0,0,0,.05);
}

.langMenu {
    position: absolute;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%) translateY(24px);
    padding: 12px 0;
    top: calc(100% + 12px);
    border-radius: 6px;
    background-color: var(--back);
    opacity: 0;
    pointer-events: none;
    transition: transform .4s cubic-bezier(0.19, 1, 0.22, 1), opacity .4s cubic-bezier(0.19, 1, 0.22, 1);
    box-shadow: 0 16px 24px -8px var(--backdrop), 0 0 0 1px var(--backdrop);
}

.langMenu.langMenuActive {
    opacity: 1;
    pointer-events: all;
    transform: translateX(-50%) translateY(0);
}

.langMenu::after {
    content: "";
    display: block;
    position: absolute;
    top: -6px;
    left: calc(50% - 6px);
    border-top-left-radius: 3px;
    height: 12px;
    width: 12px;
    box-shadow: -1px -1px 0 0 var(--backdrop);
    transform: rotate(45deg);
    background-color: var(--back);
}

.langMenuItem {
    padding: 1px 12px;
    color: var(--text);
    transition: background-color .13s ease, color .13s ease;
}

.langMenuItem:hover {
    color: var(--primary);
}

.langMenuItemSelected {
    background-color: var(--primary);
    color: var(--back)!important;
}

@media (max-width: 1182px){
    .nav{
        padding: 16px 0;
    }
    .burgerWrap{
        display: block;
        width: 36px;
        height: 36px;
    }

    .logos{
        z-index: 101;
    }

    .burger {
        position: absolute;
        padding: 9px 4px;
        z-index: 101;
        border-radius: 5px;
        cursor: pointer;
        animation: fadeIn 1.6s 1s cubic-bezier(0.19, 1, 0.22, 1) both;
    }

    .burger:hover{
        background-color: rgba(0,0,0,.05);
    }
    
    .burger div {
        width: 28px;
        height: 2px;
        background-color: var(--primary);
        transition: opacity .2s ease-out, transform .2s ease-out;
    }

    .burger div:not(:last-of-type) {
        margin-bottom: 6px;
    }
    
    .open .burger div:first-of-type {
        transform: translateY(8px) rotate(45deg);
    }
    
    .open .burger div:nth-of-type(2) {
        opacity: 0;
        transform: scale(0);
    }
    
    .open .burger div:last-of-type {
        transform: translateY(-8px) rotate(-45deg);
    }

    .links{
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        background-color: var(--back);
        padding: 72px 24px 24px 24px;   
        font-size: 16px;
        box-shadow: 0 8px 16px -4px rgba(0,0,0,.1);
        transform: translateY(-100%);
        opacity: 0;
        pointer-events: none;
        transition: transform .2s ease, opacity .2s ease;
    }

    .links a{
        display: block;
        margin: 0!important;
    }

    .open .links{
        transform: none;
        opacity: 1;
        pointer-events: all;
    }
}

@media (max-width: 400px){
    .logos>*:not(:last-child){
        margin-right: 12px;
    }
    .logos img{
        height: 32px;
    }
    .nav :global(.container){
        padding: 0 16px;
    }
    .links{
        padding: 72px 16px 24px 16px;
    }
}