.person{
    text-align: center;
    width: fit-content;
}

.personPhoto{
    height: 128px;
    width: 128px;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 12px;
}

.person p{
    font-size: 14px;
}

.date{
    margin-top: -8px;
    opacity: .8;
}

.button {
    appearance: none;
    background-color: var(--secondary);
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    margin-bottom: 12px;
    transition: background-color 200ms ease;
    cursor: pointer;
}

.button:hover {
    background-color: var(--secondaryTransparent);
}

.watch a{
    color: var(--primary);
    font-size: 14px;
    text-decoration: none;
    box-shadow: inset 0 -.07em 0 var(--primary);
    font-weight: 700;
    transition: box-shadow .47s cubic-bezier(.77,0,.175,1), color .47s cubic-bezier(.77,0,.175,1);
}

.watch a:hover {
    border-color: var(--primary);
    box-shadow: inset 0 -0.85em 0 var(--primaryTransparent);
}

