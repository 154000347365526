.post{
    display: block;
    width: 100%;
    text-decoration: none!important;
}

.imageWrap{
    overflow: hidden;
    margin-bottom: 16px;
    border-radius: 8px;
}

.imageWrap img{
    width: 100%;
    object-fit: cover;
    height: 200px;
    vertical-align: middle;
}

.post h3{
    line-height: 1.2;
}
