html{
    scroll-behavior: smooth;
}

body{
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --primary: #3C3B6E;
    --secondary: #97002D;
    --back: #ffffff;
    --backdrop: rgba(0,0,0,.1);
    --border: #eeeeee;
    --primaryTransparent: rgba(60, 59, 110, 0.6);
    --secondaryTransparent: rgba(151, 0, 45, 0.6);
    color: var(--primary);
    background-color: var(--back);
}

.container{
    max-width: 1110px;
    padding: 0 24px;
    margin: auto;
}

.anchor{
    position: absolute;
    margin-top: -128px;
}

@media (max-width: 1024px){
    .container{
        max-width: 900px;
    }
}

@media (max-width: 880px){
    .anchor{
        position: absolute;
        margin-top: -100px;
    }
}