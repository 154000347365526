.timeline{
    padding: 48px 0;
}

.timeline h1 {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.5px;
    text-align: center;
    margin-bottom: 24px;
}

.items {
    position: relative;
}

.items::before {
    content: "";
    position: absolute;
    display: block;
    width: 2px;
    height: 100%;
    left: calc(50% - 1px);
    background-color: var(--secondary);
}

.date {
    position: relative;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin: 16px 0;
}

.date::after {
    content: "";
    position: absolute;
    display: block;
    background-color: var(--secondary);
    height: 8px;
    width: 8px;
    border-radius: 50%;
    left: calc(50% - 4px);
    bottom: -16px;
}

.items+.date::after {
    top: -16px;
}

.item {
    width: 50%;
    position: relative;
    padding: 12px 0;
    cursor: pointer;
}

.item button {
    appearance: none;
    border: none;
    background-color: transparent;
    color: var(--secondary);
    padding: 0;
    
    margin-left: 8px;
}

.item button svg {
    display: block;
}

.item::after {
    content: "";
    position: absolute;
    display: block;
    height: 2px;
    width: 24px;
    background-color: var(--primary);
    top: calc(50% - 1px);
    border-radius: 2px;
}

.item::before {
    content: "";
    position: absolute;
    display: block;
    height: 6px;
    width: 6px;
    background-color: var(--primary);
    top: calc(50% - 3px);
    border-radius: 50%;
}

.item:nth-child(odd) {
    margin-right: auto;
    text-align: right;
    padding-right: 48px;
}

.item:nth-child(odd)::after {
    right: 12px;
}

.item:nth-child(odd)::before {
    right: 32px;
}

.item:nth-child(even) {
    margin-left: auto;
    padding-left: 48px;
}

.item:nth-child(even)::after {
    left: 12px;
}

.item:nth-child(even)::before {
    left: 32px
}

.item h3{
    line-height: 1.15;
}

.item div {
    display: flex;
    width: fit-content;
    align-items: center;
}

.item:nth-child(odd) div {
    margin-left: auto;
}

.item:nth-child(even) div {
    margin-right: auto;
}

.item div span {
    display: block;
    line-height: 1.2;
}


.item h3 {
    color: var(--primary);
    text-decoration: none;
    font-weight: 800;
    box-shadow: inset 0 -.07em 0 var(--primary);
    transition: box-shadow .27s cubic-bezier(.77,0,.175,1),color .27s cubic-bezier(.77,0,.175,1);
}

.item h3:hover {
    border-color: var(--primary);
    box-shadow: inset 0 -0.85em 0 var(--primaryTransparent);
}

.href {
    color: var(--primary);
    text-decoration: none;
    box-shadow: inset 0 -.07em 0 var(--primary);
    transition: box-shadow .27s cubic-bezier(.77,0,.175,1),color .27s cubic-bezier(.77,0,.175,1);
}

.href:hover {
    border-color: var(--primary);
    box-shadow: inset 0 -0.85em 0 var(--primaryTransparent);
}

@media (max-width: 768px) {
    .timeline h1 {
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.4px;
    }
}

@media (max-width: 512px) {
    .date{
        text-align: left;
        font-size: 16px;
    }
    .date::after{
        left: -3px;
    }
    .items::before{
        left: 0;
    }
    .item {
        padding: 24px 0;
        width: 100%;
        margin-left: unset!important;
        margin-right: unset!important;
        padding-right: unset!important;
        padding-left: 48px!important;
        text-align: left!important;
    }

    .item div{
        margin: unset!important;
    }

    .item::after {
        left: 12px;
        right: unset;
    }

    .item::before {
        left: 32px;
        right: unset;
    }
}