.acknowledgements{
    padding: 48px 0;
}

.acknowledgements h1{
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.5px;
    text-align: center;
    margin-bottom: 48px;
}

.text a {
    color: var(--primary);
    text-decoration: none;
    font-weight: 800;
    box-shadow: inset 0 -.07em 0 var(--primary);
    transition: box-shadow .27s cubic-bezier(.77,0,.175,1),color .27s cubic-bezier(.77,0,.175,1);
}

.text a:hover {
    border-color: var(--primary);
    box-shadow: inset 0 -0.85em 0 var(--primaryTransparent);
}

.text{
    max-width: 720px;
    margin: auto;
}

@media (max-width: 768px){
    .acknowledgements h1{
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.4px;
    }
}