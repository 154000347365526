.team{
    padding: 48px 0;
}

.team h1{
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.5px;
    text-align: center;
    margin-bottom: 24px;
}

.head{
    display: grid;
    padding: 48px 0;
    gap: 32px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "person text text";
    justify-items: center;
}

.head>:not(.text){
    grid-area: person;
}

.head .text{
    grid-area: text;
}

.members{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;
    justify-items: center;
}

@media (max-width: 768px){
    .team h1{
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.4px;
    }
    .head{
        justify-items: center;
        grid-template-columns: 1fr;
        grid-template-areas: unset;
    }
    .head>*{
        grid-area: unset!important;
    }
    .members{
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 498px){
    .members{
        grid-template-columns: 1fr;
    }
}