.page{
    padding-top: 85px;
}

.photo {
    margin-top: 48px;
}

.photo{
    background-size: cover;
    background-position: center;
    height: 196px;
    width: 196px;
    border-radius: 50%;
}

.page h2{
    font-size: 36px;
    margin-top: 24px;
}

.button{
    appearance: none;
    display: block;
    padding: 8px 12px;
    background-color: var(--primary);
    color: #fff;
    border-radius: 5px;
    width: fit-content;
    text-decoration: none!important;
    transition: background-color .2s ease;
}

.button:hover{
    background-color: var(--primaryTransparent);
}

.button.disabled{
    background-color: #bebebe;
    cursor: not-allowed;
}

.buttons{
    display: flex;
}

.buttons>*:not(:last-child){
    margin-right: 12px;
}

@media (max-width: 1182px){
    .page{
        padding-top: 69px;
    }
}

@media (max-width: 520px){
    .page{
        text-align: center;
    }
    .photo{
        margin: auto;
        height: 128px;
        width: 128px;
    }
    .buttons{
        flex-direction: column;
        align-items: center;
    }
    .buttons>*:not(:last-child){
        margin-right: 0;
        margin-bottom: 12px;
    }
}