.start{
    padding: 48px 0;
}

.start h1{
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.5px;
    text-align: center;
    margin-bottom: 48px;
}

.grid{
    display: grid;
    gap: 24px;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "steps steps cities";
}

.grid ol{
    grid-area: steps;
    font-size: 18px;
}

.grid .citiesWrap{
    grid-area: cities;
}

.grid .citiesWrap h3{
    display: flex;
    align-items: center;
}

.grid .citiesWrap h3 svg{
    margin-left: 8px;
    display: block;
}

.grid .cities{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
}

.grid ol, .grid ul{
    padding-left: 24px;
}

.steps a {
    color: var(--primary);
    text-decoration: none;
    font-weight: 800;
    box-shadow: inset 0 -.07em 0 var(--primary);
    transition: box-shadow .27s cubic-bezier(.77,0,.175,1),color .27s cubic-bezier(.77,0,.175,1);
}

.steps a:hover {
    border-color: var(--primary);
    box-shadow: inset 0 -0.85em 0 var(--primaryTransparent);
}
@media (max-width: 768px){
    .start h1{
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.4px;
    }
    .grid{
        grid-template-columns: 1fr;
        grid-template-areas: unset;
    }
    .grid ol, .grid .citiesWrap{
        grid-area: unset!important;
    }
    .grid ol, .grid ul{
        font-size: 16px;
        padding-left: 16px;
    }
    .grid .citiesWrap h3{
        text-align: left;
    }
}