.why{
    padding: 48px 0;
}

.why h1{
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.5px;
    text-align: center;
    margin-bottom: 56px;
    transform: translateY(24px);
    opacity: 0;
    transition: opacity .3s ease, transform .3s ease;
}

.show.why h1{
    transform: none;
    opacity: 1;
}

.items{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 32px;
}

.item img{
    display: block;
    height: 36px;
    margin-bottom: 12px;
    transform: scale(0);
    transition: transform .3s ease;
}

.show .item img{
    transform: none;
}

.item h3{
    font-size: 16px;
    font-weight: 500;
    opacity: 0;
    transition: opacity .2s ease;
}

.show .item h3{
    opacity: 1;
}

.item p{
    font-size: 14px;
    transform: translateY(-24px);
    opacity: 0;
    transition: opacity .3s ease, transform .3s ease;
}

.show .item p{
    transform: none;
    opacity: 1;
}


@media (max-width: 1024px){
    .items{
        grid-template-columns: 1fr 1fr;
        gap: 48px;
    }
}

@media (max-width: 768px){
    .why h1{
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.4px;
    }
    .items{
        grid-template-columns: 1fr;
    }
}