.posts{
    padding: 48px 0;
}

.posts h1{
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.5px;
    text-align: center;
    margin-bottom: 48px;
}

.items{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 48px;
}

.empty{
    text-align: center;
    padding: 48px 0;
}

@media (max-width: 768px){
    .posts h1{
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.4px;
    }
    .items{
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 598px){
    .items{
        grid-template-columns: 1fr;
    }
}